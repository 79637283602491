import { graphql, StaticQuery, Link } from "gatsby";
import React, { FunctionComponent, useContext, useState } from "react";
import { CartContext } from "../cart/CartProvider";
import { Price } from "../Price";
import Button from "react-md/lib/Buttons";
import {
	Card,
	CardTitle,
	CardText,
	Grid,
	Cell,
	SelectField,
	CardActions,
} from "react-md";

export interface StripeSku {
	price: number;
	product: {
		name: string;
		metadata: {
			maxPurchase: string;
		};
	};
	id: string;
}

interface ItemsTemplateProps {
	items: Array<StripeSku>;
}

const ItemsTemplate: FunctionComponent<ItemsTemplateProps> = ({ items }) => {
	return (
		<Grid>
			{items.map((item, i) => (
				<ItemTemplate item={item} key={i} />
			))}
		</Grid>
	);
};

const ItemTemplate: FunctionComponent<{ item: StripeSku }> = ({ item }) => {
	const { items: cartItems, cart } = useContext(CartContext);
	const [quantity, setQuantity] = useState(1);
	return (
		<Cell>
			<Card className="md-block-centered">
				<CardTitle title={item.product.name} />
				<CardText>
					<p>
						<Price value={item.price} />
					</p>
				</CardText>
				<CardActions>
					{cartItems.find(({ item: cartItem }) => cartItem.id === item.id) ? (
						<Link to="/cart">
							<Button raised secondary>
								Checkout
							</Button>
						</Link>
					) : (
						<>
							{Number(item.product.metadata.maxPurchase) > 1 && (
								<SelectField
									id={`addToCart_${item.id}`}
									menuItems={[1, 2, 3, 4, 5]}
									onChange={value => setQuantity(value as number)}
									value={quantity}
									label="Quantity"
								/>
							)}
							<Button raised primary onClick={() => cart.add(item, quantity)}>
								Buy
							</Button>
						</>
					)}
				</CardActions>
			</Card>
		</Cell>
	);
};

const ITEMS_QUERY = graphql`
	{
		items: allStripeSku(
			filter: {
				product: { id: { ne: "prod_svcfee" }, active: { eq: true } }
				active: { eq: true }
			}
			sort: { fields: product___metadata___order, order: ASC }
		) {
			nodes {
				id
				price
				product {
					name
					metadata {
						maxPurchase
					}
				}
			}
		}
	}
`;

export const Items: FunctionComponent<{}> = () => (
	<StaticQuery query={ITEMS_QUERY}>
		{({ items }) => <ItemsTemplate items={items.nodes} />}
	</StaticQuery>
);
