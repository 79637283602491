import React, { FunctionComponent } from "react";

import Layout from "../layout";
import SEO from "../components/seo";
import { Items } from "../components/items/Items";

const IndexPage: FunctionComponent<{}> = () => {
	return (
		<Layout title="Payments Page">
			<SEO title="Payments" />
			<Items />
		</Layout>
	);
};

export default IndexPage;
